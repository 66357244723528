<script setup lang="ts">
import type { CmsElementText } from "@shopware/composables";
import { useCmsElementConfig, useUrlResolver } from "#imports";
import { computed, defineComponent, getCurrentInstance, h } from "vue";
import { decodeHTML } from "entities";
import type { CSSProperties, VNode, VNodeArrayChildren } from "vue";
import { getOptionsFromNode } from "@shopware/cms-base-layer/helpers/html-to-vue/getOptionsFromNode";
import type { NodeObject } from "@shopware/cms-base-layer/helpers/html-to-vue/getOptionsFromNode";
import { renderHtml } from "@shopware/cms-base-layer/helpers/html-to-vue/renderToHtml";
type RawChildren = string | number | boolean | VNode | VNodeArrayChildren;

const props = defineProps<{
  content: CmsElementText;
}>();
const context = getCurrentInstance();
const { getConfigValue } = useCmsElementConfig(props.content);

const mappedContent = computed<string>(() => {
  return props.content?.data?.content || getConfigValue("content");
});

const style = computed<CSSProperties>(() => ({
  alignItems: getConfigValue("verticalAlign"),
}));

const hasVerticalAlignment = computed(() => !!style.value.alignItems);

const CmsTextRender = defineComponent({
  setup() {
    const { resolveUrl } = useUrlResolver();

    const config = {
      textTransformer: (text: string) => decodeHTML(text),
      extraComponentsMap: {
        link: {
          conditions(node: NodeObject) {
            return (
              node.type === "tag" &&
              node.name === "a" &&
              !node.attrs?.class?.match(/btn\s?/)
            );
          },
          renderer(
            node: NodeObject,
            children: RawChildren[],
            createElement: typeof h
          ) {
            return createElement(
              "a",
              {
                class:
                  "underline text-base font-normal text-primary hover:text-secondary-900",
                ...getOptionsFromNode(node, resolveUrl).attrs,
              },
              [...children]
            );
          },
        },
        button: {
          conditions(node: NodeObject) {
            return (
              node.type === "tag" &&
              node.name === "a" &&
              node.attrs?.class?.match(/btn\s?/)
            );
          },
          renderer(
            node: NodeObject,
            children: RawChildren[],
            createElement: typeof h
          ) {
            let _class = "";
            if (node?.class) {
              const btnClass = "";

              _class = node.class
                .replace("btn-secondary", `${btnClass} btn-outline`)
                .replace("btn-primary", `${btnClass} btn`);
            }

            return createElement(
              "a",
              {
                class: _class,
                ...getOptionsFromNode(node, resolveUrl).attrs,
              },
              [...children]
            );
          },
        },
        font: {
          conditions(node: NodeObject) {
            return node.type === "tag" && node.name === "font";
          },
          renderer(
            node: NodeObject,
            children: RawChildren[],
            createElement: typeof h
          ) {
            // convert from <font color="#ce0000">Headline 1</font> to <span style="color:#ce0000">Headline 1</span>
            let newStyle = null;
            const styleColor = node?.attrs?.color;
            if (styleColor) {
              const currentStyle = node.attrs?.style ?? "";
              newStyle = `color:${styleColor};${currentStyle}`;
              delete node.attrs?.color;
            }

            return createElement(
              "span",
              {
                style: newStyle,
                ...getOptionsFromNode(node, resolveUrl).attrs,
              },
              [...children]
            );
          },
        },
        img: {
          conditions(node: NodeObject) {
            return node.type === "tag" && node.name === "img";
          },
          renderer(
            node: NodeObject,
            children: RawChildren[],
            createElement: typeof h
          ) {
            return createElement(
              "img",
              getOptionsFromNode(node, resolveUrl)?.attrs
            );
          },
        },
      },
    };
    const rawHtml =
      mappedContent.value?.length > 0
        ? mappedContent.value
        : "<div class='cms-element-text missing-content-element'></div>";

    return () =>
      h("div", {}, renderHtml(rawHtml, config, h, context, resolveUrl));
  },
});
</script>
<template>
  <div class="container text-editor px-0 xl:px-5">
    <div
      :class="{ flex: hasVerticalAlignment, 'flex-row': hasVerticalAlignment }"
      :style="style"
    >
      <CmsTextRender />
    </div>
  </div>
</template>
